@import "../../assets/styles/variables";

.Container {
    position: fixed;
    top: 0px;
    right: 0%;
    width: 25%;
    height: 100%;
    background-color: #ffffff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
    z-index: 4;
}

.ClearIcon{
    position: absolute;
    cursor: pointer;
    color: $primaryLight;
    top: 10px;
    left: 90%;
    z-index: 3;
    &:hover{
        color: #1d3557;
    }
}

.BackIcon{
    position: absolute;
    cursor: pointer;
    color: $primaryLight;
    top: 10px;
    left: 5%;
    z-index: 1;
    &:hover{
        color: #1d3557;
    }
}

.CardContainer{
    display: flex;
    flex-direction: column;
    margin: .5rem 1rem;
    gap: .5rem;
    max-height: 75%;
    overflow: scroll;
    transition: all 1s ease-in-out;
    &::-webkit-scrollbar-thumb:hover { background-color: #fff };
    &::-webkit-scrollbar-track {
        border-radius: 15px;
        background-color: #fff;
    };
    &::-webkit-scrollbar { width: 12px; background-color: #fff };
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background-color: #fff;
    }
}

.CardContent{
    display: flex;
    flex-direction: column;
    padding: .5rem 1rem;
    gap: .4rem;
    cursor:pointer;
    &:hover{
        background-color: #ebecf0;
    }
}

.CardHeader{
    color: #1d3557;
    font-size: .7rem;
    font-weight: 500;
}
.CardDescription{
    color: $primaryLight;  
    font-size: .7rem;
    font-weight: 400;
    text-align: justify;
}

.Others{
    display: flex;
    border-top : 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 1rem;
    padding: .7rem .5rem;
    cursor: pointer;
    &:hover{
        background-color: #ebecf0;
        border-radius: 10px;
    }
}

.OthersHeader{
    color: #1d3557;
    font-size: .8rem;
    font-weight: 700;
}

.More{
    cursor: pointer;
    font-size: .7rem;
    color: #147ad6; 
    margin: 0rem 1rem;
    &:hover{
        text-decoration: underline;
    }
}

.Shadow{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
}