@import '../../../../assets/styles/variables';

.NavbarRight {
  margin-left: auto;
  gap: 10px;
  display: flex;
  align-items: center;
  color: $primaryDark;
}
.MainNavRight {
  display: flex;
  margin-right: 20px;
}
.ProfileButton {
  border-radius: 5px;
  padding: 8px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Quicksand';
}
.NameText {
  white-space: nowrap;
  margin-left: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}
.ProfileMenuItem {
  & span {
    font-size: 1rem;
    font-family: 'Quicksand';
    color: $secondaryLight;
  }
  & svg {
    color: $secondaryLight;
  }
}
