@import '../../../../assets/styles/variables';

// .navbarLeft {}

.VerticleLine {
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
}

.WelcomeMessage {
  color: $primaryDark;
  display: flex;
  flex-direction: column;

  & .title {
    font-weight: 800;
    font-size: 1.7rem;
  }

  & .desc {
    font-size: 1rem;
  }
}
.ProjectNav {
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;

  @media screen and (max-width: $lg) {
    font-size: 0.75rem;
  }
}
.NavItem {
  padding: 15px;
  color: #3f4d67;
  border-radius: 5px;
  transition: all 0.3s ease;
  white-space: nowrap;
  font-size: 0.5rem;

  &:hover {
    color: $secondaryLight;
  }

  @media screen and (min-width: $xl) {
    font-size: 1rem;
  }
}
.ActiveNavItem {
  @extend .NavItem;
  color: $secondaryLight;
  box-shadow: 0 3px 6px 0 #00000029;
  border-bottom: 4px solid $secondaryLight;
}
.ProjectsMenu {
  margin-right: 10px;
}
.ProjectsButton {
  border-radius: 8px !important;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Quicksand';
  display: flex;
  // padding: 9.6px !important;
  margin-left: -2px !important;
  color: $secondaryMain !important;

  &:focus {
    outline: none;
  }
  & svg {
    width: 30px;
    height: 30px;
  }
  & span {
    white-space: nowrap;
    display: none;

    @media screen and (min-width: $xl) {
      display: flex;
    }
  }
}
.SettingsNav {
  display: flex;
  gap: 40px;
  height: 70px;
}
.LogoLink {
  width: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 80%;
    height: auto;
    @media screen and (max-width: $lg) {
      width: 100%;
    }
    @media screen and (max-width: $md) {
      width: 120%;
    }
    @media screen and (max-width: $sm) {
      width: 280%;
    }
  }
}
.Logo {
  width: 100%;
  height: auto;
}
.HomeButton {
  display: flex;
  align-items: center;
  border-left: 1px solid $primaryMain;
  // color: $primaryMain;

  & span {
    font-size: 1.6rem;
    color: $primaryMain;
    font-weight: 500;
    text-align: center;
    display: block;

    @media screen and (max-width: $md) {
      font-size: 1rem;
    }
  }
}
