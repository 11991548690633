@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

// breakpoints
$xs: 0px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;

// body color
$bodyColor: #888888;

// fonts
$fontFamily: 'facto, sans-serif', sans-serif;

// header
$header-height: 70px;
$Menu-width: 225px;
$Menu-collapsed-width: 20px;

// paper & background
$background: #f5f5f7;
$white: #fff;

// primary
$primaryLight: #a6a6a6;
$primaryMain: #707070;
$primaryDark: #1d3557;

// secondary
$secondaryXXLight: #04a9f5;
$secondaryXLight: #449dd1;
$ImortExport: #1d3557;
$secondaryLight: #147ad6;
$secondaryMain: #3943b7;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #eef2f6;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  background: $background;

  // primary
  primaryLight: $primaryLight;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;

  // secondary
  secondaryLight: $secondaryLight;
  secondaryMain: $secondaryMain;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
