@import './variables';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  outline: none !important;
  &:focus {
    outline: none !important;
  }
}
html {
  font-family: $fontFamily !important;
}
body {
  font-family: $fontFamily !important;
  font-size: 12px !important;
  color: $bodyColor !important;
  width: 100%;
  min-height: 100vh;
  background-color: $background;
}
a {
  outline: none;
  text-decoration: none;
}
ul {
  list-style: none;
}
:root {
  --schemeColorDark: #1d3557;
  --schemeColorLight: #a7afb8;
  --schemePurple: #3943b7;
  --schemeBlue: #147ad6;
  --schemeColorWhite: #fff;
  --schemeColorGrey: #828d9b;
  --schemeColorBorder: #e2e3e5;
  --schemeBackgroundColor: #f5f5f7;
}
