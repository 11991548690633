.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 151px);
  background-color: #0000003b;
  z-index: 9999;
}
.loaderBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
