@import '../../../assets/styles/variables';

.Header {
  display: flex;
  flex-direction: column;
  min-height: $header-height;
  padding: 0;
  position: relative;
  top: 0;
  background: transparent;
  width: calc(100% - #{$Menu-width});
  margin-left: $Menu-width;
  transition: all 0.3s ease-in-out;

  .on {
    width: calc(100% - #{$Menu-collapsed-width});
    margin-left: $Menu-collapsed-width;
  }
}

.navBar {
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
}

.VerticleLine {
  height: 1px;
  margin-left: -40px;
  background-color: #707070;
}
